<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                    <router-link :to="'/incoming/detail?id=' + props.row.id">
                        <a class="btn btn-primary btn-sm text-white margin5">Detail</a>
                    </router-link>
                    <a v-on:click="deleteIncoming(props.row.id)" class="btn btn-danger btn-sm text-white margin5">
                        Hapus
                    </a>
                </span>
                <span v-else-if="props.column.field == 'text'">
                    {{ cutString(props.row.text) }}
                    <div v-if="props.row.file">
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <center>
                                    <b>Document</b>
                                    <br>
                                    <a :href="props.row.file" target="_blank"> Open File </a>
                                </center>
                            </div>
                        </div>
                    </div>
                </span>
                <span v-else-if="props.column.field == 'created_at'">
                    {{ convertDateToLocal(props.row.created_at) }}
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                table: {
                    total_records: 100,
                    isLoading: true,
                    columns: [{
                            label: "Pengirim",
                            field: "number",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "15%",
                        },
                        {
                            label: "Text",
                            field: "text",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "40%",
                        },
                        {
                            label: "Diterima Pada",
                            field: "created_at",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "25%",
                        },
                        {
                            label: "Action",
                            field: "action",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    // serverParams: {
                    //     nextKey: null
                    // },
                    serverParams: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        page: 1,
                        limit: 10,
                    },
                },
            };
        },
        mounted() {
            this.fn = () => {
                this.loadItems();
            }
            this.$root.$on("refresh_table", this.fn);
            this.loadItems();
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fn);
        },
        methods: {
            cutString(string) {
                if(string == null || string == "") return ""

                if (string.length > 40) {
                    string = string.slice(0, 40) + "..."
                }

                return string
            },
            convertDateToLocal(date) {
                return moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss")
            },
            async deleteIncoming(id) {
                try {
                    let response = await this.$store.dispatch("incoming_delete", {
                        id: id
                    });
                    this.loadItems();
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error("Server error");
                }
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    limit: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.table.isLoading = true
                try {
                    let response = await this.getFromServer(this.table.serverParams);
                    this.table.total_records = response.data.data.total_records;
                    let incoming = response.data.data.incomings;
                    // for (let i = 0; i < outgoing.length; i++) {
                    //     if(outgoing[i].message_type == "button"){
                    //         outgoing[i].parsedButton = await JSON.parse(outgoing[i].data)
                    //         outgoing[i].footer = outgoing[i].parsedButton[0].footer
                    //     }
                    // }
                    this.table.rows = incoming
                } catch (err) {
                    console.log(err)
                    this.$toast.error("Too many request");
                }
                this.table.isLoading = false
            },
            async getFromServer(param) {
                return await this.$store.dispatch("incoming_list", param);
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
</style>